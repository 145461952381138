<footer class="cl-footer cl-footer-white">
    <nav class="container">
        <div class="row">
            <div class="col-12 text-center">
                <p>
                    <a href="https://castlabs.com/privacy-cookie-policy/">Privacy &amp; cookie policy</a>
                    ·
                    <a href="https://castlabs.com/legal/">Legal</a>
                    · ©2008–2025 castLabs
                </p>
            </div>
        </div>
    </nav>
</footer>
