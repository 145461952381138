<td colspan="100%">
  <div class="container cl-spacing-small">
    <div class="row">
      <div class="col-md-6">
        <dl>
          <ng-container>
            <dt>Workflow Process</dt>
            <dd>
              <p>
              <app-process-link [processId]="poItem.workflow_process.id" [type]="'wf'"></app-process-link>
              </p>
              <p  *ngIf="poItem.workflow_process">
                <ng-container *ngIf="poItem.workflow_process.state !== 'IN_PROGRESS'">
                  {{ poItem.workflow_process.start_date | duration: poItem.workflow_process.end_date }}
                  ({{ poItem.workflow_process.start_date | date: 'd MMM y,&nbsp;HH:mm' }}
                  - {{ poItem.workflow_process.end_date | date: 'd MMM y,&nbsp;HH:mm' }})
                </ng-container>
                <ng-container *ngIf="poItem.workflow_process.state === 'IN_PROGRESS'">
                  Started: {{ poItem.workflow_process.start_date | date: 'd MMM y,&nbsp;HH:mm' }}
                </ng-container>
            </p>
            </dd>
          </ng-container>
          <ng-container *ngIf="poItem.aspect_ratio">
            <dt>Aspect ratio</dt>
            <dd>{{ poItem.aspect_ratio }}</dd>
          </ng-container>
          <ng-container *ngIf="poItem.content_identifier">
            <dt>Content Identifier</dt>
            <dd>{{ poItem.content_identifier }}</dd>
          </ng-container>
          <ng-container *ngIf="poItem.metadata_source">
            <dt>Metadata Source</dt>
            <dd>{{ poItem.metadata_source }}</dd>
          </ng-container>
          <ng-container *ngIf="poItem.wrap_up_workflow">
            <dt>Wrap Up Workflow</dt>
            <dd>{{ poItem.wrap_up_workflow }}</dd>
          </ng-container>
          <ng-container *ngIf="poItem.input_brefix">
            <dt>Input (source) directory</dt>
            <dd><a
              [routerLink]="'/o/' + this.organization.id +  '/files/browse/' + poItem.input_brefix">{{ poItem.input_brefix }}</a>
            </dd>
          </ng-container>
          <ng-container *ngIf="poItem.vtk_jobs.length > 0">
            <dt>Encoding job status</dt>
            <dd>
              <ng-container *ngFor="let vtk_job of poItem.vtk_jobs"><a *ngIf="vtk_job.id"
                                                                       class="cl-a-external"
                                                                       href="{{ VTK_SERVER }}/{{ vtk_job.organization_id }}/jobs/{{vtk_job.id}}">VTK
                Job: {{ vtk_job.id }} {{ vtk_job.status_text }}</a> <span
                *ngIf="!vtk_job.id">{{ vtk_job.status_text }}</span> <br/>

              </ng-container>
            </dd>
          </ng-container>
          <ng-container
            *ngIf="poItem.preview && (poItem.preview.dash_manifest_url || poItem.preview.hls_manifest_url)">
            <dt>Preview</dt>
            <dd><a class="cl-a"
                   [routerLink]="['/o', organization.id,  'workflows', poItem.po.po_name.split('_')[0], poItem.po.po_name, poItem.po_item_id, 'preview']"

                   role="link">Preview</a></dd>
          </ng-container>


        </dl>

      </div>
      <div class="col-md-6">

        <dl>
          <dt>Workflow type</dt>
          <dd>{{ poItem.workflow.substring(15) }}</dd>
        </dl>

        <ng-container *ngIf="poItem.vtk_template">
          <dt>VTK Template</dt>
          <dd>{{ poItem.vtk_template }}</dd>
        </ng-container>


        <ng-container *ngIf="poItem.filename">
          <dt>Output filename</dt>
          <dd>{{ poItem.filename }}<br>
            <app-s3-link [fof]="'s3://' + poItem.output_brefix"></app-s3-link>
          </dd>

        </ng-container>
        <ng-container
          *ngIf="poItem.workflow === 'start_workflow_vod_default' && poItem.publish_process?.state === 'SUCCESS'">
          <dt>CDN</dt>
          <dd><a class="cl-a-external"
                 href="https://vod{{envsuffix}}.cp.castlabs.com/{{poItem.customer}}/{{poItem.po.po_name}}/{{poItem.po_item_id}}/dash.mpd">DASH</a>/<a
            class="cl-a-external"
            href="https://vod{{envsuffix}}.cp.castlabs.com/{{poItem.customer}}/{{poItem.po.po_name}}/{{poItem.po_item_id}}/hls.m3u8">HLS</a>/<a
            class="cl-a-external"
            href="{{ poItem | vodDemoPlayer:  airline.prod_organization }}">Demo
            Player</a>
          </dd>
        </ng-container>
        <dt>Publication</dt>
        <dd>
          <p>{{ poItem.po_destination }}</p>
          <p>
            <app-process-link *ngIf="poItem.publish_process" [processId]="poItem.publish_process.id"
                              [type]="'wf'"></app-process-link>
          </p>
          <p *ngIf="poItem.publish_process">
            <ng-container *ngIf="poItem.publish_process.state !== 'IN_PROGRESS'">
              {{ poItem.publish_process.start_date | duration: poItem.publish_process.end_date }}
              ({{ poItem.publish_process.start_date | date: 'd MMM y,&nbsp;HH:mm' }}
              - {{ poItem.publish_process.end_date | date: 'd MMM y,&nbsp;HH:mm' }})
            </ng-container>
            <ng-container *ngIf="poItem.publish_process.state === 'IN_PROGRESS'">
              Started: {{ poItem.publish_process.start_date | date: 'd MMM y,&nbsp;HH:mm' }}
            </ng-container>
          </p>
        </dd>


      </div>
    </div>
    <div class="col-12 ">
      <table style="table-layout: fixed;" class="cl-table-data">
        <thead>
        <tr>
          <th class="cl-table-head cl-p-tiny ">
            requested
          </th>
          <th class="cl-table-head cl-p-tiny ">
            selected
          </th>
          <th class="cl-table-head cl-p-tiny ">
            messages
          </th>
        </tr>
        </thead>
        <tbody *ngIf="poItem.tracks.length > 0; else bodyNoData">
        <tr *ngFor="let element of poItem.tracks">
          <td style="white-space: nowrap">{{ element.codec_type }} {{ element.lang }}</td>
          <td>
            <ng-container *ngIf="element.source">

              <dt>{{ element.source.key | remove:poItem.output_brefix }}</dt>
              <dd *ngIf="element.source.coded_width ">
                Resolution: {{ element.source.coded_width }}x{{ element.source.coded_height }}
              </dd>
              <dd *ngIf="element.source.language ">
                Language (BCP-47): {{ element.source.language }}
              </dd>
              <dd *ngIf="element.source.codec_type ">
                Type: {{ element.source.codec_type }}
              </dd>
            </ng-container>
          </td>
          <td>
            <ul>
              <li *ngFor="let msg of element.messages" style="white-space: break-spaces;">{{ msg }}</li>
            </ul>
          </td>

        </tr>
        </tbody>
        <ng-template #bodyNoData>
          <tbody>
          <tr class="cl-tr-no-data">
            <td colspan="3" style="text-align: center">No files available.</td>
          </tr>
          </tbody>
        </ng-template>
      </table>
    </div>
    <div class="col-12">
      <dl>
        <dt>Format Specific Data</dt>
        <dd>
          <pre>{{ poItem.format_specific_data_json | json }}</pre>
        </dd>

      </dl>
    </div>
  </div>
</td>
