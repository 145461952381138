
export class Constants {
  public static languages = [
    {name: 'Arabic', id: 'ar'},
    {name: 'Argentinian', id: 'es-AR'},
    {name: 'Bangla (Bengali)', id: 'bn'},
    {name: 'Bhojpuri', 'id': 'bho'},
    {name: 'Catalan', id: 'ca'},
    {name: 'Chinese Cantonese', id: 'yue'},
    {name: 'Chinese Mandarin', id: 'cmn'},
    {name: 'Chinese, Tradt\'l Script', id: 'zh-Hant'},
    {name: 'Chinese, Simplified', id: 'zh-Hans'},
    {name: 'Czech', id: 'cs'},
    {name: 'Danish', id: 'da'},
    {name: 'Dutch', id: 'nl'},
    {name: 'English', id: 'en'},
    {name: 'English Descriptive Audio', id: 'en-x-da'},
    {name: 'Farsi', id: 'fa'},
    {name: 'Finnish', id: 'fi'},
    {name: 'French, Canadian', id: 'fr-CA'},
    {name: 'French', id: 'fr'},
    {name: 'French, Parisian', id: 'fr-FR'},
    {name: 'Gaelic, Scottish', id: 'gd'},
    {name: 'German', id: 'de'},
    {name: 'Gujarati', id: 'gu'},
    {name: 'Greek', id: 'el'},
    {name: 'Hebrew', id: 'he'},
    {name: 'Hindi', id: 'hi'},
    {name: 'Hungarian', id: 'hu'},
    {name: 'Icelandic', id: 'is'},
    {name: 'Indonesian', id: 'id'},
    {name: 'Inuktitut', id: 'iu'},
    {name: 'Irish', id: 'ga'},
    {name: 'Italian', id: 'it'},
    {name: 'Japanese', id: 'ja'},
    {name: 'Kannada', id: 'kn'},
    {name: 'Kazakh', id: 'kk'},
    {name: 'Korean', id: 'ko'},
    {name: 'Malay', id: 'ms'},
    {name: 'Malayalam', id: 'ml'},
    {name: 'Maori', id: 'mi'},
    {name: 'Marathi', id: 'mr'},
    {name: 'Music and Effects', id: 'x-me'},
    {name: 'Nepali', id: 'ne'},
    {name: 'Norwegian', id: 'no'},
    {name: 'Polish', id: 'pl'},
    {name: 'Portuguese, Brazilian', id: 'pt-BR'},
    {name: 'Portuguese, European', id: 'pt-PT'},
    {name: 'Portuguese', id: 'pt'},
    {name: 'Punjabi', id: 'pa'},
    {name: 'Romanian', id: 'ro'},
    {name: 'Russian', id: 'ru'},
    {name: 'Serbian', id: 'sr'},
    {name: 'Sinhala', id: 'si'},
    {name: 'Slovenian', id: 'sl'},
    {name: 'Spanish', id: 'es'},
    {name: 'Spanish, Castilian', id: 'es-ES'},
    {name: 'Spanish, Latin American', id: 'es-419'},
    {name: 'Swahili', id: 'sw'},
    {name: 'Swedish', id: 'sv'},
    {name: 'Tagalog', id: 'tl'},
    {name: 'Telugu', id: 'te'},
    {name: 'Tamil', id: 'ta'},
    {name: 'Thai', id: 'th'},
    {name: 'Turkish', id: 'tr'},
    {name: 'Ukrainian', id: 'uk'},
    {name: 'Urdu', id: 'ur'},
    {name: 'Vietnamese', id: 'vi'},
    {name: 'Yoruba', id: 'yo'},
    {name: 'Undefined', id: 'und'},
  ];
}
